import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from '@reach/router';
import { Container, Group, Text, Title, Space, LoadingOverlay, Dialog } from '@aviationexam/core';
import { Locale, usePublicTranslation } from 'features/localization';
import { studentUserClient } from 'utils/api';
import { SEO } from 'components';
import { useDataLayer } from 'hooks';
import useStyles from './AbortAccountDeletion.styles';

interface AbortAccountDeletionPageProps {
  locale: Locale;
}

interface NotificationProps {
  open: boolean;
  type?: 'error' | 'success';
}

export function AbortAccountDeletionPage({ locale }: AbortAccountDeletionPageProps) {
  const { t } = usePublicTranslation();
  const location = useLocation();
  const urlParam = location.pathname.split('/').slice(-1);
  const { classes } = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [dialog, setDialog] = useState<NotificationProps>({ open: false });

  const abortAccountDeletion = useCallback(async secret => {
    try {
      studentUserClient.cancelRequestDeleteUserProfile({ code: secret });
      setIsLoading(false);
    } catch (error) {
      setDialog({ open: true, type: 'error' });
    }
  }, []);

  useEffect(() => {
    abortAccountDeletion(`${urlParam[0]}`);
  }, [location]);

  return (
    <>
      <SEO
        title={t('abortAccountDeletionPage.title')}
        description={t('site.description')}
        lang={locale}
        meta={[{ name: 'robots', content: 'noindex' }]}
      >
        {useDataLayer({
          web: { lang: locale },
          page: {
            type: 'other',
            section: 'other',
          },
        })}
        {/* {alternateLinks} */}
      </SEO>
      <Container size="xs">
        <Group direction="column" spacing="lg" grow>
          <LoadingOverlay visible={isLoading} />
          <Space h="xs" />
          <Title align="center" order={2} className={classes.subtitle}>
            {t('abortAccountDeletionPage.title')}
          </Title>
          {isLoading ? (
            <>
              <Text color="dimmed">{t('abortAccountDeletionPage.loading')}</Text>
              <Space h="xl" />
            </>
          ) : (
            <>
              <Text color="dimmed">{t('abortAccountDeletionPage.requestSuccess')}</Text>
              <Space h="xl" />
            </>
          )}
          <Dialog
            opened
            withCloseButton
            onClose={() => setDialog({ open: false })}
            size="lg"
            radius="md"
          >
            <Text size="sm" style={{ marginBottom: 10 }} weight={500}>
              {dialog.type === 'error'
                ? t('abortAccountDeletionPage.requestError')
                : t('abortAccountDeletionPage.requestSuccess')}
            </Text>
          </Dialog>
        </Group>
      </Container>
    </>
  );
}
