import React from 'react';
import { PageProps } from 'gatsby';
import { Locale } from 'features/localization';
import { ExternistNode } from 'types/externist';
import { AbortAccountDeletionPage } from 'features/abort-deletion';

interface DataType {
  allExternistsJson: {
    nodes: ExternistNode[];
  };
}

function Page({ pageContext: { locale } }: PageProps<DataType, { locale: Locale }>) {
  return <AbortAccountDeletionPage locale={locale} />;
}

export default Page;
