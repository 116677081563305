import { createStyles } from '@aviationexam/core';

export default createStyles(theme => ({
  subtitle: {
    fontSize: 48,
    fontWeight: 300,
    textAlign: 'center',
    color: theme.colors.gray[6],
  },
}));
